import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import ValuesContext from '../../contexts/values-context/ValuesContext';
import { EditorState } from 'draft-js';

const TableFormModal = () => {
  const {
    formModalOpened,
    setFormModalOpened,
    selectedTableRow,
    setSelectedTableRow,
    setValuesDescEditorStateAr,
    setValuesDescEditorStateEn
  } = useContext(ValuesContext);

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedTableRow ? 'تعديل' : 'إضافة جديد'}
        </div>
      }
      visible={formModalOpened}
      onOk={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
        setValuesDescEditorStateAr(EditorState.createEmpty());
        setValuesDescEditorStateEn(EditorState.createEmpty());
      }}
      onCancel={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
        setValuesDescEditorStateAr(EditorState.createEmpty());
        setValuesDescEditorStateEn(EditorState.createEmpty());
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default TableFormModal;
