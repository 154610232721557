import { useContext } from 'react';
import { CategoriesProvider } from '../../contexts/categories-context/CategoriesContext';
import { SlidesProvider } from '../../contexts/slides-context/SlidesContext';
import UserContext from '../../contexts/user-context/UserProvider';
import SlidesPage from '../../pages/slides-page/SlidesPage';
import CategoriesPage from '../../pages/categories-page/CategoriesPage';
import ServicesPage from '../../pages/services-page/ServicesPage';
import protectMe from '../../utils/protectMe';
import routerLinks from './routerLinks';
import { ClientsProvider } from '../../contexts/clients-context/ClientsContext';
import ClientsPage from '../../pages/clients-page/ClientsPage';
import { ServicesProvider } from '../../contexts/services-context/ServicesContext';
import { ValuesProvider } from '../../contexts/values-context/ValuesContext';
import ValuesPage from '../../pages/values-page/ValuesPage';
import { FaqProvider } from '../../contexts/faq-context/FaqContext';
import FaqPage from '../../pages/faq-page/FaqPage';
import PackagesPage from '../../pages/packages-page/PackagesPage';
import { PackagesProvider } from '../../contexts/packages-context/PackagesContext';
import DiscountPage from '../../pages/discounts-page/DiscountPage';
import { DiscountProvider } from '../../contexts/discount-context/DiscountContext';
import OrdersPage from '../../pages/orders-page/OrdersPage';
import { OrdersProvider } from '../../contexts/orders-context/OrdersContext';
import { OrderDetailsProvider } from '../../contexts/order-details-context/OrderDetailsContext';
import OrderDetailsPage from '../../pages/order-details-page/OrderDetailsPage';
import ContactMessagesPage from '../../pages/contact-messages-page/ContactMessagesPage';
import { ContactMessagesProvider } from '../../contexts/contact-messages-context/ContactMessagesContext';
import SocialMediaLinksPage from '../../pages/social-media-links-page/SocialMediaLinksPage';
import SettingsPage from '../../pages/settings-page/SettingsPage';

const MainAppBarRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  return [
    protectMe(
      routerLinks?.slidesRoute,
      <SlidesProvider>
        <SlidesPage />
      </SlidesProvider>,

      1,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),

    protectMe(
      routerLinks?.categoriesRoute,
      <CategoriesProvider>
        <CategoriesPage />
      </CategoriesProvider>,
      2,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),

    protectMe(
      routerLinks?.clientsRoute,
      <ClientsProvider>
        <ClientsPage />
      </ClientsProvider>,
      3,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.servicesRoute,
      <ServicesProvider>
        <ServicesPage />
      </ServicesProvider>,
      4,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.ourValuesRoute,
      <ValuesProvider>
        <ValuesPage />
      </ValuesProvider>,
      5,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.faqRoute,
      <FaqProvider>
        <FaqPage />
      </FaqProvider>,
      5,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.packagesRoute,
      <PackagesProvider>
        <PackagesPage />
      </PackagesProvider>,
      6,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.discountRoute,
      <DiscountProvider>
        <DiscountPage />
      </DiscountProvider>,
      7,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.ordersRoute,
      <OrdersProvider>
        <OrdersPage />
      </OrdersProvider>,
      8,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.orderDetailsRoute(),
      <OrderDetailsProvider>
        <OrderDetailsPage />
      </OrderDetailsProvider>,
      9,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.contactMessagesRoute,
      <ContactMessagesProvider>
        <ContactMessagesPage />
      </ContactMessagesProvider>,
      10,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.socialLinksRoute,
      <SocialMediaLinksPage />,
      11,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks?.settingsRoute,
      <SettingsPage />,
      12,
      routerLinks?.signinPage,
      loggedIn,
      user
    )
  ];
};

export default MainAppBarRoutes;
