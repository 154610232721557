/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSelectedPage from '../../custom-hooks/useSelectedPage';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import { Form, Button } from 'antd';
import appPages from '../../appPages';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getAllSocialMediaLinksApi from '../../apis/social-media-links-apis/getAllSocialMediaLinksApi';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import updateSocialMediaLinks from '../../apis/social-media-links-apis/updateSocialMediaLinksApi';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import './SocialMediaLinksPage.scss';

const SocialMediaLinksPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { socialMediaLinksPage } = appPages(i18n, t, user);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [fetchedSocialData, setFetchedSocialData] = useState(null);
  const [fetchDataCount, setFetchDataCount] = useState(0);
  useSelectedPage({
    altName: (
      <div className="title-icon-text-wrap">
        {socialMediaLinksPage?.icon ? (
          <socialMediaLinksPage.icon
            width={28}
            height={28}
            style={{
              fontSize: '28px',
              verticalAlign: 'middle',
              display: 'grid',
              alignItems: 'center'
            }}
          />
        ) : null}
        <span className="title-span">{socialMediaLinksPage?.name}</span>
      </div>
    ),
    currentPage: socialMediaLinksPage
  });

  const customApiRequest = useCustomApiRequest();
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      facebook: '',
      twitter: '',
      instagram: '',
      google: '',
      youtube: ''
    }
  });

  useEffect(() => {
    let isMounted = true;
    setIsLoadingData(true);
    customApiRequest(
      getAllSocialMediaLinksApi(null, user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingData(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedSocialData(data);
          }
        }
      },
      (error) => {
        setIsLoadingData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchDataCount, i18n.language]);

  // handle initial values
  useEffect(() => {
    if (fetchedSocialData) {
      fetchedSocialData?.facebook &&
        setValue('facebook', fetchedSocialData.facebook);
      fetchedSocialData?.instagram &&
        setValue('instagram', fetchedSocialData.instagram);
      fetchedSocialData?.google && setValue('google', fetchedSocialData.google);
      fetchedSocialData?.twitter &&
        setValue('twitter', fetchedSocialData.twitter);
      fetchedSocialData?.youtube &&
        setValue('youtube', fetchedSocialData.youtube);
    }
  }, [fetchedSocialData]);

  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.facebook) formData.append('facebook', data.facebook);
    if (data.instagram) formData.append('instagram', data.instagram);
    if (data.google) formData.append('google', data.google);
    if (data.twitter) formData.append('twitter', data.twitter);
    if (data.youtube) formData.append('youtube', data.youtube);

    setIsLoadingData(true);

    if (fetchedSocialData) {
      customApiRequest(
        updateSocialMediaLinks(user?.token, formData, i18n.language, true),
        (res) => {
          setIsLoadingData(false);
          if (checkRes(res)) {
            setFetchDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <Form
          className="social-media-links-form custom-shared-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <div className="form-body">
            <div className="all-fields-wrap">
              <div className="text-field-label-wrap">
                <p className="label-p">Facebook</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="facebook"
                    type="text"
                    placeholder="Facebook..."
                    errorMsg={errors?.facebook?.message}
                    validateStatus={errors?.facebook ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">Twitter</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="twitter"
                    type="text"
                    placeholder="Twitter..."
                    errorMsg={errors?.twitter?.message}
                    validateStatus={errors?.twitter ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">Instagram</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="instagram"
                    type="text"
                    placeholder="Instagram..."
                    errorMsg={errors?.instagram?.message}
                    validateStatus={errors?.instagram ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">Google</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="google"
                    type="text"
                    placeholder="Google..."
                    errorMsg={errors?.google?.message}
                    validateStatus={errors?.google ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">Youtube</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="youtube"
                    type="text"
                    placeholder="Youtube..."
                    errorMsg={errors?.youtube?.message}
                    validateStatus={errors?.youtube ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
            </div>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isLoadingData}
            >
              حفــظ
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SocialMediaLinksPage;
