/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Tabs } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import FileInput from '../../common/file-input/FileInput';
import getTableFormSchema from './tableFormSchema';
import i18n from '../../i18n';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import ServicesContext from '../../contexts/services-context/ServicesContext';
import './TableForm.scss';
import CustomEditor from '../../common/custom-editor/CustomEditor';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createNewServiceApi from '../../apis/services-apis/createNewServiceApi';
import deleteServiceImageApi from '../../apis/services-apis/deleteServiceImageApi';

const calcServiePriceAfterDiscount = (price, offer) => {
  let total = 0;
  if (parseFloat(price) > 0 && parseFloat(offer) >= 0) {
    total = parseFloat(price) - (parseFloat(price) * parseFloat(offer)) / 100;
  }

  return total.toFixed(2);
};

const calcOfferValue = (price, offer) => {
  let total = 0;
  if (parseFloat(price) > 0 && parseFloat(offer) >= 0) {
    total = (parseFloat(price) * parseFloat(offer)) / 100;
  }
  return total.toFixed(2);
};

const TableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);

  const {
    allFetchedTableData,
    setFormModalOpened,
    isLoadingTableData,
    setIsLoadingTableData,
    setFetchTableDataCount,
    selectedTableRow,
    setSelectedTableRow,
    descEditorStateAr,
    setDescEditorStateAr,
    descEditorStateEn,
    setDescEditorStateEn
  } = useContext(ServicesContext);
  const schema = getTableFormSchema(selectedTableRow);
  const {
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    setError,
    clearErrors,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name_ar: '',
      name_en: '',
      cat_id: '',
      price: '',
      hasOffer: '',
      offerValue: '',
      systemType: '',
      maxNumWorker: '',
      desc_ar: '',
      desc_en: '',
      image: null,
      icon: null,
      images: null
    }
  });

  console.log('watch : ', watch());
  console.log('errors : ', errors);

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      selectedTableRow?.name_ar &&
        setValue('name_ar', selectedTableRow.name_ar);
      selectedTableRow?.name_en &&
        setValue('name_en', selectedTableRow.name_en);
      selectedTableRow?.cat_id &&
        setValue('cat_id', String(selectedTableRow.cat_id));
      setValue('price', selectedTableRow.price);
      setValue(
        'hasOffer',
        selectedTableRow?.hasOffer == 0 ? '2' : selectedTableRow.hasOffer
      );
      setValue('offerValue', selectedTableRow?.offerValue);
      setValue('systemType', selectedTableRow.systemType);
      setValue('maxNumWorker', selectedTableRow.maxNumWorker);
    }
  }, [selectedTableRow]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.name_ar) formData.append('name_ar', data.name_ar);
    if (data.name_en) formData.append('name_en', data.name_en);
    if (data.desc_ar) formData.append('desc_ar', data.desc_ar);
    if (data.desc_en) formData.append('desc_en', data.desc_en);
    if (data.cat_id) formData.append('cat_id', data.cat_id);
    formData.append('systemType', data.systemType);
    formData.append('maxNumWorker', data.maxNumWorker);
    formData.append('price', data.price);
    formData.append('hasOffer', data.hasOffer);
    if (data.hasOffer == '1') {
      formData.append('offerValue', data.offerValue);
      formData.append(
        'offerPrice',
        calcOfferValue(data.price, data.offerValue)
      );
    }

    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }
    if (data?.icon?.length > 0) {
      formData.append('icon', data.icon[0]);
    }
    if (data?.images?.length > 0) {
      for (let i of data.images) formData.append('images[]', i);
    }

    setIsLoadingTableData(true);

    if (!selectedTableRow) {
      customApiRequest(
        createNewServiceApi(user?.token, formData, i18n.language),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تمت الإضافة بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('Service_id', selectedTableRow.id);

      customApiRequest(
        createNewServiceApi(user?.token, formData, i18n.language, true),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const deleteImg = (id) => {
    setIsLoadingTableData(true);
    customApiRequest(
      deleteServiceImageApi(
        user?.token,
        {
          image_id: id
        },
        i18n.language
      ),
      (res) => {
        setIsLoadingTableData(false);
        if (checkRes(res)) {
          successNotification({
            title: 'العملية تمت بنجاح',
            message: 'تم حذف الصورة بنجاح'
          });
          setFetchTableDataCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsLoadingTableData(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  useEffect(() => {
    if (allFetchedTableData?.length > 0) {
      const found = allFetchedTableData.find(
        (obj) => obj?.id === selectedTableRow?.id
      );
      if (found) {
        setSelectedTableRow({ ...found });
      }
    }
  }, [allFetchedTableData]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-service-form custom-shared-form"
      form={form}
      fields={[
        {
          name: 'hasOffer',
          value: watch('hasOffer')
        }
      ]}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="name-phone-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">الاسم بالعربية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name_ar"
                type="text"
                placeholder="الاسم بالعربية..."
                errorMsg={errors?.name_ar?.message}
                validateStatus={errors?.name_ar ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">الاسم بالانجليزية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name_en"
                type="text"
                placeholder="الاسم بالانجليزية..."
                errorMsg={errors?.name_en?.message}
                validateStatus={errors?.name_en ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="name-phone-wrap">
          <div className="select-label-wrap">
            <p className="label-p">نوع الخدمة</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="systemType"
                errorMsg={errors?.systemType?.message}
                validateStatus={errors?.systemType ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="نوع الخدمة"
                options={[
                  {
                    title: 'النوع الاول',
                    value: '1'
                  },
                  {
                    title: 'النوع الثانى',
                    value: '2'
                  }
                ]}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="create-service-form"
              />
            </div>
          </div>

          <div className="text-field-label-wrap">
            <p className="label-p">أقصى عدد من العمال</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="maxNumWorker"
                type="text"
                placeholder="أقصى عدد من العمال..."
                errorMsg={errors?.maxNumWorker?.message}
                validateStatus={errors?.maxNumWorker ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">سعر الخدمة</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder="سعر الخدمة..."
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="radios-wrap">
          <p className="radios-group-title">هل يوجد خصم</p>
          <div className="labels-wrap">
            {[
              {
                title: 'نعم',
                value: '1'
              },
              {
                title: 'لا',
                value: '2'
              }
            ].map((obj, i) => (
              <label
                key={i}
                className={`radio-input-label ${
                  String(watch('hasOffer')) === String(obj.value)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={obj.value}
                  {...register(`hasOffer`)}
                />
                <span className="label-span">{obj.title}</span>
                {watch(`hasOffer`) == String(obj.value) ? (
                  <RadioButtonFilled />
                ) : (
                  <RadioButtonEmpty />
                )}
              </label>
            ))}
          </div>

          {errors?.hasOffer && (
            <p className="error-p">{errors.hasOffer.message}</p>
          )}

          {String(watch(`hasOffer`)) == '1' && (
            <div className="before-after-wrap">
              <div className="text-field-label-wrap">
                <p className="label-p">نسبة الخصم</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    // disabled={selectedRecipt ? true : false}
                    className="form-text-field"
                    name={`offerValue`}
                    type="text"
                    placeholder="نسبة الخصم..."
                    errorMsg={errors.offerValue && errors.offerValue.message}
                    validateStatus={errors.offerValue ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: 12
                }}
              >
                {!isNaN(parseInt(watch(`price`))) &&
                  !isNaN(parseInt(watch(`offerValue`))) && (
                    <div className="after-wrap">
                      <div className="after-title">سعر الخصم</div>
                      <span className="after-value">
                        {!isNaN(parseInt(watch(`price`))) &&
                        !isNaN(parseInt(watch(`offerValue`)))
                          ? calcOfferValue(watch('price'), watch('offerValue'))
                          : null}
                      </span>
                    </div>
                  )}
                {!isNaN(parseInt(watch(`price`))) &&
                  !isNaN(parseInt(watch(`offerValue`))) && (
                    <div className="after-wrap">
                      <div className="after-title">السعر بعد الخصم</div>
                      <span className="after-value">
                        {!isNaN(parseInt(watch(`price`))) &&
                        !isNaN(parseInt(watch(`offerValue`)))
                          ? calcServiePriceAfterDiscount(
                              watch('price'),
                              watch('offerValue')
                            )
                          : null}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane forceRender={true} tab="النص باللغة العربية" key="1">
            <CustomEditor
              fieldName="desc_ar"
              // editorTitle="About tournament ( English )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="تفاصيل الخدمة باللغة العربية"
              editorFieldState={descEditorStateAr}
              setEditorFieldState={setDescEditorStateAr}
              fetchedData={selectedTableRow?.desc_ar}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            forceRender={true}
            tab="تفاصيل الخدمة باللغة الانجليزية"
            key="2"
          >
            <CustomEditor
              fieldName="desc_en"
              // editorTitle="About tournament ( arabic )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="أدخل النص باللغة الإنجليزية"
              editorFieldState={descEditorStateEn}
              setEditorFieldState={setDescEditorStateEn}
              fetchedData={selectedTableRow?.desc_en}
            />
          </Tabs.TabPane>
        </Tabs>

        <div className="main-image-icon-wrap">
          <div className="main-image-wrap">
            <FileInput
              name="image"
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              label="صورة الخدمة"
              accept="image/*"
              multiple={false}
              setValue={setValue}
              watch={watch}
              register={register}
              unregister={unregister}
              // setUrls={setUrls}
              dropzoneText="إسحب صورة الخدمة وضعها هنا ..."
              className="product-images-dropzone"
              dropzoneUrls={
                selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
              }
              canDelete={false}
              showError={false}
              errorMsg={errors?.image?.message}
            />
          </div>

          <div className="main-icon-wrap">
            <FileInput
              name="icon"
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              label="أيقونة الخدمة"
              accept="image/*"
              multiple={false}
              setValue={setValue}
              watch={watch}
              register={register}
              unregister={unregister}
              // setUrls={setUrls}
              dropzoneText="أيقونة الخدمة ..."
              className="product-images-dropzone"
              dropzoneUrls={
                selectedTableRow?.icon ? [{ url: selectedTableRow.icon }] : []
              }
              canDelete={false}
              showError={false}
              errorMsg={errors?.icon?.message}
            />
          </div>
        </div>

        <div className="images-wrap">
          <FileInput
            name="images"
            label=""
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            accept="image/*"
            multiple={true}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صور الخدمة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.images?.length > 0
                ? selectedTableRow.images.map((i) => ({
                    id: i.id,
                    url: i.image
                  }))
                : []
            }
            canDelete={true}
            handleDeleteImgReq={(id) => deleteImg(id)}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoadingTableData}
        >
          {selectedTableRow ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
