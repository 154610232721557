import { EditorState } from 'draft-js';
import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoadingTableData: false,
  setIsLoadingTableData: (v) => {},
  formModalOpened: false,
  setFormModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchTableDataCount: 0,
  setFetchTableDataCount: (v) => {},
  allFetchedTableData: null,
  setAllFetchedTableData: (v) => {},
  descEditorStateAr: EditorState.createEmpty(),
  setDescEditorStateAr: (v) => {},
  descEditorStateEn: EditorState.createEmpty(),
  setDescEditorStateEn: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  selectedTableRow: null,
  setSelectedTableRow: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    mame: '',
    status: ''
  },
  setTableFilter: (v) => {}
};

const DiscountContext = createContext(INITIAL_VALUES);

export const DiscountProvider = ({ children }) => {
  const [isLoadingTableData, setIsLoadingTableData] = useState(
    INITIAL_VALUES.isLoading
  );
  const [fetchTableDataCount, setFetchTableDataCount] = useState(
    INITIAL_VALUES.fetchTableDataCount
  );
  const [allFetchedTableData, setAllFetchedTableData] = useState(
    INITIAL_VALUES.allFetchedTableData
  );
  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES.selectedTableRow
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES.tablePagination
  );
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES.modalOpened
  );
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  //
  const [descEditorStateAr, setDescEditorStateAr] = useState(
    INITIAL_VALUES.descEditorStateAr
  );
  const [descEditorStateEn, setDescEditorStateEn] = useState(
    INITIAL_VALUES.descEditorStateEn
  );
  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);

  return (
    <DiscountContext.Provider
      value={{
        isLoadingTableData,
        setIsLoadingTableData,
        formModalOpened,
        setFormModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchTableDataCount,
        setFetchTableDataCount,
        allFetchedTableData,
        setAllFetchedTableData,
        selectedTableRow,
        setSelectedTableRow,
        //
        descEditorStateAr,
        setDescEditorStateAr,
        descEditorStateEn,
        setDescEditorStateEn,
        //
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};

export default DiscountContext;
