import React, { useContext } from 'react';
import PageTable from './PageTable';
import useSelectedPage from '../../custom-hooks/useSelectedPage';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import appPages from '../../appPages';
import './ContactMessagesPage.scss';

const ContactMessagesPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { contactMessagesPage } = appPages(i18n, t, user);

  useSelectedPage({
    altName: (
      <div className="title-icon-text-wrap">
        {contactMessagesPage?.icon ? (
          <contactMessagesPage.icon
            width={28}
            height={28}
            style={{
              fontSize: '28px',
              verticalAlign: 'middle',
              display: 'grid',
              alignItems: 'center'
            }}
          />
        ) : null}
        <span className="title-span">{contactMessagesPage?.name}</span>
      </div>
    ),
    currentPage: contactMessagesPage
  });
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <PageTable />
      </div>
    </div>
  );
};

export default ContactMessagesPage;
