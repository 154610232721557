import React from 'react';
import { Empty, Pagination, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const SharedAntdTable = ({
  hasPagination,
  tableDataSource,
  tableColumns,
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  isLoadingTableData,
  setIsLoadingTableData,
  setSelectedTableRow,
  user,
  //
  tablePaination,
  baseTablePageUrl
}) => {
  const navigate = useNavigate();
  const customApiRequest = useCustomApiRequest();
  const { i18n } = useTranslation();
  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
        }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedTableData,
          setFormModalOpened,
          setDetailsModalOpened,
          setFetchTableDataCount,
          setSelectedTableRow,
          setIsLoadingTableData,
          user?.token,
          customApiRequest,
          i18n
        )}
        // loading={isLoadingTableData}
        loading={{
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
          spinning: isLoadingTableData
        }}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {hasPagination && (
        <Pagination
          defaultCurrent={1}
          current={tablePaination.current_page}
          pageSize={tablePaination.per_page}
          total={tablePaination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchTableDataCount((prev) => prev + 1);
            navigate(`${baseTablePageUrl}?page=${page}`);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default SharedAntdTable;
