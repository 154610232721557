import appPages from '../../appPages';

const mainAppBarLinks = (i18n, t, user) => {
  const {
    homePage,
    slidesPage,
    categoriesPage,
    servicesPage,
    clientsPage,
    ordersPage,
    valuesPage,
    faqPage,
    contactMessagesPage,
    socialMediaLinksPage,
    packagesPage,
    discountsPage,
    settingsPage
  } = appPages(i18n, t, user);
  if (user || !user) {
    return [
      {
        id: homePage?.id,
        name: homePage?.name,
        link: homePage?.link,
        icon: homePage?.icon ? <homePage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.slidesPage?.id,
        name: slidesPage?.name,
        link: slidesPage?.link,
        icon: slidesPage?.icon ? <slidesPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.categoriesPage?.id,
        name: categoriesPage?.name,
        link: categoriesPage?.link,
        icon: categoriesPage?.icon ? <categoriesPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.servicesPage?.id,
        name: servicesPage?.name,
        link: servicesPage?.link,
        icon: servicesPage?.icon ? <servicesPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.clientsPage?.id,
        name: clientsPage?.name,
        link: clientsPage?.link,
        icon: clientsPage?.icon ? <clientsPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.ordersPage?.id,
        name: ordersPage?.name,
        link: ordersPage?.link,
        icon: ordersPage?.icon ? <ordersPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.valuesPage?.id,
        name: valuesPage?.name,
        link: valuesPage?.link,
        icon: valuesPage?.icon ? <valuesPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.faqPage?.id,
        name: faqPage?.name,
        link: faqPage?.link,
        icon: faqPage?.icon ? <faqPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.contactMessagesPage?.id,
        name: contactMessagesPage?.name,
        link: contactMessagesPage?.link,
        icon: contactMessagesPage?.icon ? <contactMessagesPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.socialMediaLinksPage?.id,
        name: socialMediaLinksPage?.name,
        link: socialMediaLinksPage?.link,
        icon: socialMediaLinksPage?.icon ? <socialMediaLinksPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.packagesPage?.id,
        name: packagesPage?.name,
        link: packagesPage?.link,
        icon: packagesPage?.icon ? <packagesPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.discountsPage?.id,
        name: discountsPage?.name,
        link: discountsPage?.link,
        icon: discountsPage?.icon ? <discountsPage.icon /> : null
      },
      {
        id: appPages(i18n, t, user)?.settingsPage?.id,
        name: settingsPage?.name,
        link: settingsPage?.link,
        icon: settingsPage?.icon ? <settingsPage.icon /> : null
      }
    ];
  }
  // return [
  //   {
  //     id: 1,
  //     name: t('main_app_bar_links.home'),
  //     link: routerLinks.homePage
  //   },
  //   {
  //     id: 2,
  //     name: t('main_app_bar_links.categories'),
  //     link: routerLinks.categoriesRoute
  //   },
  //   {
  //     id: 4,
  //     name: t('main_app_bar_links.startSelling'),
  //     link: routerLinks.startSellingRoute
  //   },
  //   {
  //     id: 5,
  //     name: t('main_app_bar_links.aboutUs'),
  //     link: routerLinks.aboutUsRoute
  //   }
  // ];
};

export default mainAppBarLinks;
