/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import FileInput from '../../common/file-input/FileInput';
import getTableFormSchema from './tableFormSchema';
import i18n from '../../i18n';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import createNewClientApi from '../../apis/clients-apis/createNewClientApi';
import './TableForm.scss';

const TableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);

  const {
    setFormModalOpened,
    isLoadingTableData,
    setIsLoadingTableData,
    setFetchTableDataCount,
    selectedTableRow
  } = useContext(ClientsContext);
  const schema = getTableFormSchema(selectedTableRow);
  const {
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name_ar: '',
      name_en: '',
      image: null
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      selectedTableRow?.name_ar &&
        setValue('name_ar', selectedTableRow.name_ar);
      selectedTableRow?.name_en &&
        setValue('name_en', selectedTableRow.name_en);
    }
  }, [selectedTableRow]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.name_ar) formData.append('name_ar', data.name_ar);
    if (data.name_en) formData.append('name_en', data.name_en);
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }

    setIsLoadingTableData(true);

    if (!selectedTableRow) {
      customApiRequest(
        createNewClientApi(user?.token, formData, i18n.language),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تمت الإضافة بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('Client_id', selectedTableRow.id);

      customApiRequest(
        createNewClientApi(user?.token, formData, i18n.language, true),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="create-delegate-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="name-phone-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">الاسم بالعربية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name_ar"
                type="text"
                placeholder="الاسم بالعربية..."
                errorMsg={errors?.name_ar?.message}
                validateStatus={errors?.name_ar ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">الاسم بالانجليزية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name_en"
                type="text"
                placeholder="الاسم بالانجليزية..."
                errorMsg={errors?.name_en?.message}
                validateStatus={errors?.name_en ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="file-inputs-wrap">
          <FileInput
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="الصورة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoadingTableData}
        >
          {selectedTableRow ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
