import React, { useContext } from 'react';
import PageTable from './PageTable';
import SelectedRowDetailsModal from './SelectedRowDetailsModal';
import TableFormModal from './TableFormModal';
import TableFilter from './TableFilter';
import useSelectedPage from '../../custom-hooks/useSelectedPage';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import appPages from '../../appPages';
import ServicesContext from '../../contexts/services-context/ServicesContext';
import './ServicesPage.scss';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const ServicesPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { formModalOpened, detailsModalOpened, isLoadingTableData } =
    useContext(ServicesContext);
  const { servicesPage } = appPages(i18n, t, user);

  useSelectedPage({
    altName: (
      <div className="title-icon-text-wrap">
        {servicesPage?.icon ? (
          <servicesPage.icon
            width={28}
            height={28}
            style={{
              fontSize: '28px',
              verticalAlign: 'middle',
              display: 'grid',
              alignItems: 'center'
            }}
          />
        ) : null}
        <span className="title-span">{servicesPage?.name}</span>
      </div>
    ),
    currentPage: servicesPage
  });
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        {/* <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">Slides</h2>
            </div>
          </div>
        </div> */}

        <TableFilter />
        <PageTable />

        {formModalOpened && <TableFormModal />}
        {detailsModalOpened && <SelectedRowDetailsModal />}
        {isLoadingTableData && <LoadingModal />}
      </div>
    </div>
  );
};

export default ServicesPage;
