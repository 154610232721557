import { Navigate, Route } from 'react-router-dom';
import AppLayout from '../components/app/Layout';
import routerLinks from '../components/app/routerLinks';
const protectMe = (path, component, key, redirectRoute, loggedIn, user) => {
  return (
    <Route
      exact
      path={path}
      key={key}
      element={
        loggedIn ? (
          <AppLayout>{component}</AppLayout>
        ) : (
          <Navigate
            to={redirectRoute ? redirectRoute : routerLinks.signinPage}
          />
        )
      }
    />
  );
};

export default protectMe;
