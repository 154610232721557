/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Button } from 'antd';
import AddCircledIcon from '../../common/icons/AddCircledIcon';
import PackagesContext from '../../contexts/packages-context/PackagesContext';
import './TableFilter.scss';

const TableFilter = () => {
  const { setFormModalOpened, setSelectedTableRow } =
    useContext(PackagesContext);
  // const [filterValues, setFilterValues] = React.useState({
  //   mame: '',
  //   status: ''
  // });

  return (
    <div className="custom-table-fitler" style={{ marginTop: 22 }}>
      <div className="form-excel-export-wrap">
        <div className="add-section">
          <Button
            type="primary"
            className="custom-shared-btn"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              height: 42,
              marginLeft: 18
            }}
            onClick={() => {
              setSelectedTableRow(null);
              setFormModalOpened(true);
            }}
          >
            <AddCircledIcon color="#fff" />
            إضافة جديد
          </Button>
        </div>
        {/* <form className="filter-form" onSubmit={handleSubmit}>
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم المندوب للبحث"
                type="text"
                name="name"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </label>
          </div>

          <label className="entries-wrap">
            <span className="select-title">إختار الموظف</span>
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange('status', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الحالات</Option>
              {allUserStatusArr?.length > 0 &&
                allUserStatusArr.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form> */}
      </div>
    </div>
  );
};
export default TableFilter;
