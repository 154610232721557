import React from 'react';

const CaretDownIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '24'}
        height={height || '24'}
        viewBox="0 0 24 24"
        fill={color ? color : '#5f758e'}
      >
        <path d="m11.998 17 7-8h-14z"></path>
      </svg>
    </span>
  );
};

export default CaretDownIcon;
