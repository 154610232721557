const routerLinks = {
  ////////////////////////////
  homePage: '/',
  ///////////////////////////
  signinPage: '/signin',
  signupPage: '/signup',
  profilePage: '/profile',
  ////////////////////////////
  // ...servicesRouterLinks,
  ////////////////////////////
  slidesRoute: '/slides',
  categoriesRoute: '/categories',
  servicesRoute: '/services',
  clientsRoute: '/clients',
  ordersRoute: '/orders',
  orderDetailsRoute: (orderId) =>
    orderId ? `/orders/${orderId}` : '/orders/:orderId',
  ourValuesRoute: '/our-values',
  faqRoute: '/faq',
  contactMessagesRoute: '/contact-messages',
  socialLinksRoute: '/social-links',
  packagesRoute: '/packages',
  discountRoute: '/discount',
  settingsRoute: '/settings',
  ////////////////////////////////
  aboutUsRoute: '/about-us',
  ////////////////////////////////
  notFound: '/not-found'
  //////////////////////////////
};

export default routerLinks;
