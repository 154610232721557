import * as Yup from 'yup';

const createNewFormSchema = Yup.object().shape({
  name_ar: Yup.string().required('أدخل الاسم باللغة العربية'),
  name_en: Yup.string().required('أدخل الاسم باللغة الانجليزية'),
  icon: Yup.mixed().required('أدخل الصورة')
});
const editFormSchema = Yup.object().shape({
  name_ar: Yup.string().required('أدخل الاسم باللغة العربية'),
  name_en: Yup.string().required('أدخل الاسم باللغة الانجليزية')
});
const getTableFormSchema = (selectedTableRow) => {
  if (!selectedTableRow) {
    return createNewFormSchema;
  }
  return editFormSchema;
};

export default getTableFormSchema;
