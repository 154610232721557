import React, { lazy, useContext } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { ForgetPasswordProvider } from '../../contexts/forget-password-context/ForgetPasswordContext';
import UserContext from '../../contexts/user-context/UserProvider';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import ProfilePage from '../../pages/profile-page/ProfilePage';
import SigninPage from '../../pages/signin-page/SigninPage';
// import SignupPage from '../../pages/signup-page/SignupPage';
// import ServicesRoutes from './services-routes/ServicesRoutes';
import protectMe from '../../utils/protectMe';
import AppLayout from './Layout';
import MainAppBarRoutes from './MainAppBarRoutes';
import routerLinks from './routerLinks';
const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  const { loggedIn, user } = useContext(UserContext);
  return (
    <Switch>
      <Route
        exact
        path={routerLinks.homePage}
        element={
          loggedIn ? (
            <AppLayout>
              <HomePage />
            </AppLayout>
          ) : (
            <Navigate to={routerLinks?.signinPage} />
          )
        }
      />

      {/* <Route
        exact
        path={routerLinks.signupPage}
        element={
          !loggedIn ? <SignupPage /> : <Navigate to={routerLinks.notFound} />
        }
      /> */}
      <Route
        exact
        path={routerLinks.signinPage}
        element={
          !loggedIn ? (
            <ForgetPasswordProvider>
              <SigninPage />
            </ForgetPasswordProvider>
          ) : (
            <Navigate to={routerLinks.notFound} />
          )
        }
      />

      {protectMe(
        routerLinks.profilePage,
        <ProfilePage />,
        987989,
        routerLinks?.signinPage,
        loggedIn,
        user
      )}

      {MainAppBarRoutes()}
      {/* {ServicesRoutes()} */}

      <Route path="*" element={<NotFoundPage />} />
    </Switch>
  );
};

export default Routes;
