import { nanoid } from 'nanoid';
import CatsIcon from './common/icons/CatsIcon';
import ContactMessagesIcon from './common/icons/ContactMessagesIcon';
import FaqIcon from './common/icons/FaqIcon';
import GroupIcon from './common/icons/GroupIcon';
import HomeIcon from './common/icons/HomeIcon';
import OffersIcon from './common/icons/OffersIcon';
import PackageIcon from './common/icons/PackageIcon';
import ServicesIcon from './common/icons/ServicesIcon';
import SettingsIcon from './common/icons/SettingsIcon';
import SlideShowIcon from './common/icons/SlideShowIcon';
import SocialLinksIcon from './common/icons/SocialLinksIcon';
import ValuesIcon from './common/icons/ValuesIcon';
import routerLinks from './components/app/routerLinks';

const appPages = (i18n, t, user) => ({
  homePage: {
    id: 'main_app_bar_link_1',
    name: t('main_app_bar_links.home'),
    link: routerLinks?.homePage,
    icon: ({ ...props }) => (
      <HomeIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  slidesPage: {
    id: 'main_app_bar_link_2',
    name: t('main_app_bar_links.slides'),
    link: routerLinks.slidesRoute,
    icon: ({ ...props }) => (
      <SlideShowIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  categoriesPage: {
    id: 'main_app_bar_link_3',
    name: t('main_app_bar_links.categories'),
    link: routerLinks.categoriesRoute,
    icon: ({ ...props }) => (
      <CatsIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  servicesPage: {
    id: 'main_app_bar_link_4',
    name: t('main_app_bar_links.services'),
    link: routerLinks.servicesRoute,
    icon: ({ ...props }) => (
      <ServicesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  clientsPage: {
    id: 'main_app_bar_link_5',
    name: t('main_app_bar_links.clients'),
    link: routerLinks.clientsRoute,
    icon: ({ ...props }) => (
      <GroupIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  ordersPage: {
    id: 'main_app_bar_link_6',
    name: t('main_app_bar_links.orders'),
    link: routerLinks.ordersRoute,
    icon: ({ ...props }) => (
      <PackageIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  orderDetailsPage: (orderId) => ({
    id: 'main_app_bar_link_7',
    name: t('main_app_bar_links.order_details'),
    link: routerLinks.orderDetailsRoute(orderId),
    icon: ({ ...props }) => (
      <PackageIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  }),
  valuesPage: {
    id: 'main_app_bar_link_8',
    name: t('main_app_bar_links.ourValues'),
    link: routerLinks.ourValuesRoute,
    icon: ({ ...props }) => (
      <ValuesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  faqPage: {
    id: 'main_app_bar_link_9',
    name: t('main_app_bar_links.faq'),
    link: routerLinks.faqRoute,
    icon: ({ ...props }) => (
      <FaqIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  contactMessagesPage: {
    id: 'main_app_bar_link_10',
    name: t('main_app_bar_links.contactMessages'),
    link: routerLinks.contactMessagesRoute,
    icon: ({ ...props }) => (
      <ContactMessagesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  socialMediaLinksPage: {
    id: 'main_app_bar_link_11',
    name: t('main_app_bar_links.socialLinks'),
    link: routerLinks.socialLinksRoute,
    icon: ({ ...props }) => (
      <SocialLinksIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  packagesPage: {
    id: 'main_app_bar_link_12',
    name: t('main_app_bar_links.packages'),
    link: routerLinks.packagesRoute,
    icon: ({ ...props }) => (
      <ContactMessagesIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  discountsPage: {
    id: 'main_app_bar_link_13',
    name: t('main_app_bar_links.discount'),
    link: routerLinks.discountRoute,

    icon: ({ ...props }) => (
      <OffersIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  },
  settingsPage: {
    id: 'main_app_bar_link_14',
    name: t('main_app_bar_links.settings'),
    link: routerLinks.settingsRoute,
    icon: ({ ...props }) => (
      <SettingsIcon
        width={20}
        height={20}
        style={{
          fontSize: '20px',
          verticalAlign: 'middle'
        }}
        {...props}
      />
    )
  }
});

export default appPages;
