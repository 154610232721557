/* eslint-disable eqeqeq */
import { Button, Popconfirm, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import checkRes from '../../utils/checkRes';
import deleteSingleClientApi from '../../apis/clients-apis/deleteSingleClientApi';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n
) => {
  const handleDeleteRequest = (id) => {
    setIsLoadingTableData(true);
    customApiRequest(
      deleteSingleClientApi(
        token,
        {
          Client_id: id
        },
        i18n.language
      ),
      (res) => {
        setIsLoadingTableData(false);
        if (checkRes(res)) {
          setFetchTableDataCount((prev) => prev + 1);
          successNotification({
            title: 'العملية تمت بنجاح',
            message: 'تم الحذف بنجاح'
          });
        } else {
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsLoadingTableData(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(record)}>
          <EditIcon />
          تعديل
        </div>
        {/* <Popconfirm
          title="هل أنت متأكد من الحذف"
          onConfirm={() => handleDeleteRequest(record.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف">
            <Button
              size="large"
              type="dashed"
              shape="round"
              danger
              icon={<TrashIcon width={18} height={18} />}
              style={{
                backgroundColor: '#fbebeb',
                padding: 0,
                width: 38,
                height: 38,
                lineHeight: '42px'
              }}
            />
          </Tooltip>
        </Popconfirm> */}
      </div>
    );
  };

  const handleEditClick = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  return [
    {
      title: 'إســم صاحب الطلب',
      dataIndex: 'order_user',
      render: (_, record) => {
        return record?.user?.name ? record.user.name : '---';
      }
    },
    {
      title: 'سعر الخدمة',
      dataIndex: 'service_price',
      render: (_, record) => {
        return record?.servicePrice;
      }
    },
    {
      title: 'سعر الخصم',
      dataIndex: 'discount_price',
      render: (_, record) => {
        return record?.discountPrice;
      }
    },
    {
      title: 'السعر الاجمالى',
      dataIndex: 'total_price',
      render: (_, record) => {
        return record?.totalPrice;
      }
    },

    {
      title: 'تفاصيل الطلب',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <RouterLink to={routerLinks?.orderDetailsRoute(record?.id)}>
              <Button
                size="large"
                type="dashed"
                shape="round"
                icon={<EyeOutlined />}
                style={{
                  width: 38,
                  height: 38
                }}
              />
            </RouterLink>
          </Tooltip>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
