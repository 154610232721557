/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSelectedPage from '../../custom-hooks/useSelectedPage';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import { Form, Button, Tabs } from 'antd';
import appPages from '../../appPages';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import getSettingsApi from '../../apis/settings-apis/getSettingsApi';
import CustomMap from '../../components/custom-map/CustomMap';
import FileInput from '../../common/file-input/FileInput';
import CustomEditor from '../../common/custom-editor/CustomEditor';
import { EditorState } from 'draft-js';
import updateSettingsApi from '../../apis/settings-apis/updateSettingsApi';
import './SettingsPage.scss';

const SettingsPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { settingsPage } = appPages(i18n, t, user);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [fetchedSettings, setFetchedSettings] = useState(null);
  const [fetchDataCount, setFetchDataCount] = useState(0);
  useSelectedPage({
    altName: (
      <div className="title-icon-text-wrap">
        {settingsPage?.icon ? (
          <settingsPage.icon
            width={28}
            height={28}
            style={{
              fontSize: '28px',
              verticalAlign: 'middle',
              display: 'grid',
              alignItems: 'center'
            }}
          />
        ) : null}
        <span className="title-span">{settingsPage?.name}</span>
      </div>
    ),
    currentPage: settingsPage
  });

  const customApiRequest = useCustomApiRequest();
  const {
    setValue,
    handleSubmit,
    control,
    register,
    unregister,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      phone: '',
      email: '',
      whatsapp: '',
      appleLink: '',
      androidLink: '',
      aboutImage: null,
      //
      about_ar: '',
      about_en: '',
      terms_ar: '',
      terms_en: '',
      privacy_ar: '',
      privacy_en: '',
      address_ar: '',
      address_en: '',
      //
      lat: '',
      lng: '',
      //
      facebook: '',
      twitter: '',
      instagram: '',
      google: '',
      youtube: ''
    }
  });

  useEffect(() => {
    let isMounted = true;
    setIsLoadingData(true);
    customApiRequest(
      getSettingsApi(null, user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingData(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedSettings(data);
          }
        }
      },
      (error) => {
        setIsLoadingData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchDataCount, i18n.language]);

  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');
  //
  const [editorStateAboutAr, setEditorStateAboutAr] = useState(
    EditorState.createEmpty()
  );
  const [editorStateAboutEn, setEditorStateAboutEn] = useState(
    EditorState.createEmpty()
  );
  const [editorStateTermsAr, setEditorStateTermsAr] = useState(
    EditorState.createEmpty()
  );
  const [editorStateTermsEn, setEditorStateTermsEn] = useState(
    EditorState.createEmpty()
  );
  const [editorStatePrivacyAr, setEditorStatePrivacyAr] = useState(
    EditorState.createEmpty()
  );
  const [editorStatePrivacyEn, setEditorStatePrivacyEn] = useState(
    EditorState.createEmpty()
  );
  const [editorStateAddressAr, setEditorStateAddressAr] = useState(
    EditorState.createEmpty()
  );
  const [editorStateAddressEn, setEditorStateAddressEn] = useState(
    EditorState.createEmpty()
  );
  //

  // handle initial values
  useEffect(() => {
    if (fetchedSettings) {
      fetchedSettings?.email && setValue('email', fetchedSettings.email);
      fetchedSettings?.phone && setValue('phone', fetchedSettings.phone);
      fetchedSettings?.whatsapp &&
        setValue('whatsapp', fetchedSettings.whatsapp);
      fetchedSettings?.appleLink &&
        setValue('appleLink', fetchedSettings.appleLink);
      fetchedSettings?.androidLink &&
        setValue('androidLink', fetchedSettings.androidLink);
      //
      if (fetchedSettings?.lat && fetchedSettings?.lng) {
        setSelecectedLocation({
          lat: parseFloat(fetchedSettings.lat),
          lng: parseFloat(fetchedSettings.lng)
        });
      }
      //
      fetchedSettings?.facebook &&
        setValue('facebook', fetchedSettings.facebook);
      fetchedSettings?.instagram &&
        setValue('instagram', fetchedSettings.instagram);
      fetchedSettings?.google && setValue('google', fetchedSettings.google);
      fetchedSettings?.twitter && setValue('twitter', fetchedSettings.twitter);
      fetchedSettings?.youtube && setValue('youtube', fetchedSettings.youtube);
    }
  }, [fetchedSettings]);

  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.phone) formData.append('phone', data.phone);
    if (data.email) formData.append('email', data.email);
    if (data.whatsapp) formData.append('whatsapp', data.whatsapp);
    if (data.appleLink) formData.append('appleLink', data.appleLink);
    if (data.androidLink) formData.append('androidLink', data.androidLink);
    if (data?.aboutImage?.length > 0) {
      formData.append('aboutImage', data.aboutImage[0]);
    }
    formData.append('terms_ar', data.terms_ar);
    formData.append('terms_en', data.terms_en);
    formData.append('privacy_ar', data.privacy_ar);
    formData.append('privacy_en', data.privacy_en);
    formData.append('address_ar', data.address_ar);
    formData.append('address_en', data.address_en);
    formData.append('about_ar', data.about_ar);
    formData.append('about_en', data.about_en);
    //
    formData.append(
      'lat',
      selectedLocation?.lat ? selectedLocation.lat : '23.8859'
    );
    formData.append(
      'lng',
      selectedLocation?.lng ? selectedLocation.lng : '45.0792'
    );
    //
    if (data.facebook) formData.append('facebook', data.facebook);
    if (data.instagram) formData.append('instagram', data.instagram);
    if (data.google) formData.append('google', data.google);
    if (data.twitter) formData.append('twitter', data.twitter);
    if (data.youtube) formData.append('youtube', data.youtube);

    setIsLoadingData(true);

    if (fetchedSettings) {
      customApiRequest(
        updateSettingsApi(user?.token, formData, i18n.language, true),
        (res) => {
          setIsLoadingData(false);
          if (checkRes(res)) {
            setFetchDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <Form
          className="app-settings-form custom-shared-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <div className="form-body">
            <div className="all-fields-wrap">
              <div className="basic-info-fields-wrap">
                <div className="text-field-label-wrap">
                  <p className="label-p">رقم الهاتف</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="phone"
                      type="text"
                      placeholder="رقم الهاتف..."
                      errorMsg={errors?.phone?.message}
                      validateStatus={errors?.phone ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">البريد الاكترونى</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="email"
                      type="text"
                      placeholder="البريد الاكترونى..."
                      errorMsg={errors?.email?.message}
                      validateStatus={errors?.email ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">WhatsApp</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="whatsapp"
                      type="text"
                      placeholder="WhatsApp..."
                      errorMsg={errors?.whatsapp?.message}
                      validateStatus={errors?.whatsapp ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">Apple link</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="appleLink"
                      type="text"
                      placeholder="Apple link..."
                      errorMsg={errors?.appleLink?.message}
                      validateStatus={errors?.appleLink ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">Android link</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="androidLink"
                      type="text"
                      placeholder="Android link..."
                      errorMsg={errors?.androidLink?.message}
                      validateStatus={errors?.androidLink ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              <div className="editors-wrap">
                {/*  */}
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    forceRender={true}
                    tab="عن الشركة باللغة العربية"
                    key="1"
                  >
                    <CustomEditor
                      fieldName="about_ar"
                      // editorTitle="About tournament ( English )"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="عن الشركة باللغة العربية"
                      editorFieldState={editorStateAboutAr}
                      setEditorFieldState={setEditorStateAboutAr}
                      fetchedData={fetchedSettings?.about_ar}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    forceRender={true}
                    tab="عن الشركة باللغة الانجليزية"
                    key="2"
                  >
                    <CustomEditor
                      fieldName="about_en"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="عن الشركة باللغة الانجليزية"
                      editorFieldState={editorStateAboutEn}
                      setEditorFieldState={setEditorStateAboutEn}
                      fetchedData={fetchedSettings?.about_en}
                    />
                  </Tabs.TabPane>
                </Tabs>
                {/*  */}
                {/*  */}
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    forceRender={true}
                    tab="الشروط والاحكام باللغة العربية"
                    key="1"
                  >
                    <CustomEditor
                      fieldName="terms_ar"
                      // editorTitle="About tournament ( English )"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="الشروط والاحكام باللغة العربية"
                      editorFieldState={editorStateTermsAr}
                      setEditorFieldState={setEditorStateTermsAr}
                      fetchedData={fetchedSettings?.terms_ar}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    forceRender={true}
                    tab="الشروط والاحكام باللغة الانجليزية"
                    key="2"
                  >
                    <CustomEditor
                      fieldName="terms_en"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="الشروط والاحكام باللغة الانجليزية"
                      editorFieldState={editorStateTermsEn}
                      setEditorFieldState={setEditorStateTermsEn}
                      fetchedData={fetchedSettings?.terms_en}
                    />
                  </Tabs.TabPane>
                </Tabs>
                {/*  */}
                {/*  */}
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    forceRender={true}
                    tab="سياسة الخصوصية باللغة العربية"
                    key="1"
                  >
                    <CustomEditor
                      fieldName="privacy_ar"
                      // editorTitle="About tournament ( English )"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="سياسة الخصوصية باللغة العربية"
                      editorFieldState={editorStatePrivacyAr}
                      setEditorFieldState={setEditorStatePrivacyAr}
                      fetchedData={fetchedSettings?.privacy_ar}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    forceRender={true}
                    tab="سياسة الخصوصية باللغة الانجليزية"
                    key="2"
                  >
                    <CustomEditor
                      fieldName="privacy_en"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="سياسة الخصوصية باللغة الانجليزية"
                      editorFieldState={editorStatePrivacyEn}
                      setEditorFieldState={setEditorStatePrivacyEn}
                      fetchedData={fetchedSettings?.privacy_en}
                    />
                  </Tabs.TabPane>
                </Tabs>
                {/*  */}
                {/*  */}
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    forceRender={true}
                    tab="العنوان باللغة العربية"
                    key="1"
                  >
                    <CustomEditor
                      fieldName="address_ar"
                      // editorTitle="About tournament ( English )"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="العنوان باللغة العربية"
                      editorFieldState={editorStateAddressAr}
                      setEditorFieldState={setEditorStateAddressAr}
                      fetchedData={fetchedSettings?.address_ar}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    forceRender={true}
                    tab="الشروط والاحكام باللغة الانجليزية"
                    key="2"
                  >
                    <CustomEditor
                      fieldName="address_en"
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg="الشروط والاحكام باللغة الانجليزية"
                      editorFieldState={editorStateAddressEn}
                      setEditorFieldState={setEditorStateAddressEn}
                      fetchedData={fetchedSettings?.address_en}
                    />
                  </Tabs.TabPane>
                </Tabs>
                {/*  */}
              </div>
              <div className="file-inputs-wrap">
                <FileInput
                  name="aboutImage"
                  // accept="image/png, image/jpg, image/jpeg, image/gif"
                  label="صورة عن الشركة"
                  accept="image/*"
                  multiple={false}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  unregister={unregister}
                  // setUrls={setUrls}
                  dropzoneText="اسحب الصورة ..."
                  className="product-images-dropzone"
                  dropzoneUrls={
                    fetchedSettings?.imageAbout
                      ? [{ url: fetchedSettings.imageAbout }]
                      : []
                  }
                  canDelete={false}
                  showError={false}
                  errorMsg={errors?.aboutImage?.message}
                />
              </div>

              <CustomMap
                width="100%"
                height="400px"
                selectedLocation={selectedLocation}
                setSelecectedLocation={setSelecectedLocation}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
              />

              <div className="social-fields-wrap">
                <div className="text-field-label-wrap">
                  <p className="label-p">Facebook</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="facebook"
                      type="text"
                      placeholder="Facebook..."
                      errorMsg={errors?.facebook?.message}
                      validateStatus={errors?.facebook ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">Twitter</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="twitter"
                      type="text"
                      placeholder="Twitter..."
                      errorMsg={errors?.twitter?.message}
                      validateStatus={errors?.twitter ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">Instagram</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="instagram"
                      type="text"
                      placeholder="Instagram..."
                      errorMsg={errors?.instagram?.message}
                      validateStatus={errors?.instagram ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">Google</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="google"
                      type="text"
                      placeholder="Google..."
                      errorMsg={errors?.google?.message}
                      validateStatus={errors?.google ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">Youtube</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name="youtube"
                      type="text"
                      placeholder="Youtube..."
                      errorMsg={errors?.youtube?.message}
                      validateStatus={errors?.youtube ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isLoadingData}
            >
              حفــظ
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SettingsPage;
