import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MainAppBar from '../main-app-bar/MainAppBar';
import MainAppFooter from '../main-app-footer/MainAppFooter';
import UserContext from '../../contexts/user-context/UserProvider';
import useSignout from '../../custom-hooks/useSignout';
import Avatar from 'antd/lib/avatar/avatar';
import routerLinks from './routerLinks';
import CaretDownIcon from '../../common/icons/CaretDownIcon';
import SelectedPageContext from '../../contexts/selected-page-context/SelectedPageContext';
import './Layout.scss';

const AppLayout = ({ children }) => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const { selectedPage } = useContext(SelectedPageContext);
  const { signMeOut, isLoadingSignout } = useSignout();
  const handleSignout = () => {
    signMeOut();
  };
  // const { Header, Content, Footer, Sider } = AntdLayout;

  return (
    <>
      <div className={`app-layout app-${i18n.dir()}`}>
        <MainAppBar />
        <div className="layout-content">
          <div className="layout-header">
            {selectedPage?.altName && (
              <div className="page-main-title">{selectedPage.altName}</div>
            )}

            <Dropdown
              arrow
              trigger={['click']}
              // disabled={loadingSignout}
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<UserOutlined />}>
                    <RouterLink to={routerLinks?.profilePage}>
                      الملــف الشخصى
                    </RouterLink>
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    icon={<LogoutOutlined />}
                    onClick={handleSignout}
                  >
                    تسجيل الخروج
                  </Menu.Item>
                </Menu>
              }
            >
              <Button className="profile-menu-btn" type="text">
                <Avatar
                  size={38}
                  icon={<UserOutlined />}
                  src={user?.image}
                  style={{}}
                />
                {isLoadingSignout ? (
                  <LoadingOutlined />
                ) : (
                  <CaretDownIcon width={18} />
                )}
              </Button>
            </Dropdown>
          </div>
          <div className="layout-children">{children}</div>
        </div>
        {/* <ReactNotification className={i18n.dir()} /> */}
      </div>
      <MainAppFooter />
    </>
  );
};

export default AppLayout;
