/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Modal } from 'antd';
import parse from 'html-react-parser';
import './SelectedRowDetailModal.scss';
import DiscountContext from '../../contexts/discount-context/DiscountContext';

const SelectedRowDetailsModal = () => {
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedTableRow,
    selectedTableRow
  } = useContext(DiscountContext);

  const renderMainInfo = () => {
    return (
      <div
        className="modal-main-info"
        style={{
          marginBottom: 12
        }}
      >
        {selectedTableRow?.name_ar && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الاسم باللغة العربية</div>
            <div className="info-value">{parse(selectedTableRow.name_ar)}</div>
          </div>
        )}
        {selectedTableRow.name_en && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الاسم باللغة الانجليزية</div>
            <div className="info-value">{parse(selectedTableRow.name_en)}</div>
          </div>
        )}
      </div>
    );
  };
  const renderRestInfo = () => {
    return (
      <>
        <div
          className="modal-main-info"
          style={{
            marginBottom: 12
          }}
        >
          {selectedTableRow?.desc_ar && (
            <div className="custom-render-info-wrap">
              <div className="info-label">الوصف باللغة العربية</div>
              <div className="info-value">
                {parse(selectedTableRow.desc_ar)}
              </div>
            </div>
          )}
          {selectedTableRow.desc_en && (
            <div className="custom-render-info-wrap">
              <div className="info-label">الوصف باللغة الانجليزية</div>
              <div className="info-value">
                {parse(selectedTableRow.desc_en)}
              </div>
            </div>
          )}
        </div>
        <div
          className="modal-main-info"
          style={{
            marginBottom: 12
          }}
        >
          {String(selectedTableRow?.code) && (
            <div className="custom-render-info-wrap">
              <div className="info-label">كــود الخصم</div>
              <div className="info-value">{selectedTableRow.code}</div>
            </div>
          )}
          {selectedTableRow?.expire_data && (
            <div className="custom-render-info-wrap">
              <div className="info-label">تاريخ انتهاء صلاحية الكود</div>
              <div className="info-value">{selectedTableRow.expire_data}</div>
            </div>
          )}
        </div>
        <div
          className="modal-main-info"
          style={{
            marginBottom: 12
          }}
        >
          {selectedTableRow?.amount_type && (
            <div className="custom-render-info-wrap">
              <div className="info-label">نوع كود الخصم</div>
              <div className="info-value">
                {selectedTableRow?.amount_type == '1' && 'كود خصم رقم ثابت'}
                {selectedTableRow?.amount_type == '2' && 'كود خصم بالنسبة'}
              </div>
            </div>
          )}
          {selectedTableRow?.amount ? (
            <div className="custom-render-info-wrap">
              <div className="info-label">الخصم</div>
              <div className="info-value">
                {selectedTableRow?.amount_type == '1' &&
                  `${selectedTableRow.amount}`}
                {selectedTableRow?.amount_type == '2' &&
                  `${selectedTableRow.amount} %`}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const renderImage = () => {
    return (
      <div className="slide-img-wrap">
        <img src={selectedTableRow.image} alt="" />
      </div>
    );
  };

  return (
    <Modal
      transitionName=""
      className="slides-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">التفاصيل</div>}
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedTableRow(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedTableRow(null);
      }}
      footer={false}
    >
      {renderImage()}
      {renderMainInfo()}
      {renderRestInfo()}
    </Modal>
  );
};

export default SelectedRowDetailsModal;
