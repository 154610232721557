/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Button, Select } from 'antd';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';
import SelectDropDown from '../../common/icons/SelectDropDown';
import SearchIcon from '../../common/icons/SearchIcon';
import AddCircledIcon from '../../common/icons/AddCircledIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import allUserStatusArr from '../../utils/allUserStatusArr';
import routerLinks from '../../components/app/routerLinks';
import SlidesContext from '../../contexts/slides-context/SlidesContext';
import getAllSlidesApi from '../../apis/slides-apis/getAllSlidesApi';
import i18n from '../../i18n';
import './TableFilter.scss';

const TableFilter = () => {
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const navigate = useNavigate();
  const {
    setIsLoadingTableData,
    setAllFetchedTableData,
    setTablePagination,
    setFormModalOpened,
    setSelectedTableRow,
    tableFilter,
    setTableFilter
  } = useContext(SlidesContext);
  // const [filterValues, setFilterValues] = React.useState({
  //   mame: '',
  //   status: ''
  // });
  async function handleChange(filterType, value) {
    // console.log(`selected ${value}`);
    setTableFilter((prevState) => {
      if (filterType === 'name') {
        return {
          ...prevState,
          name: value
        };
      } else if (filterType === 'status') {
        return {
          ...prevState,
          status: value
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoadingTableData(true);
      const res = await getAllSlidesApi(
        {
          ...tableFilter,
          ...values,
          page: 1
        },
        user?.token,
        i18n.language
      );

      // is response is success
      if (checkRes(res) && res.data?.data?.data?.length >= 0) {
        const data = res.data.data.data;
        navigate(`${routerLinks.slides}?page=1`);
        setAllFetchedTableData(data);
        if (res.data.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }

        setIsLoadingTableData(false);
      } else {
        setIsLoadingTableData(false);
      }
    } catch (error) {
      setIsLoadingTableData(false);
      console.log(error);
    }
  }

  return (
    <div className="custom-table-fitler" style={{ marginTop: 22 }}>
      <div className="form-excel-export-wrap">
        <div className="add-section">
          <Button
            type="primary"
            className="custom-shared-btn"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              height: 42,
              marginLeft: 18
            }}
            onClick={() => {
              setSelectedTableRow(null);
              setFormModalOpened(true);
            }}
          >
            <AddCircledIcon color="#fff" />
            إضافة جديد
          </Button>
        </div>
        {/* <form className="filter-form" onSubmit={handleSubmit}>
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم المندوب للبحث"
                type="text"
                name="name"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </label>
          </div>

          <label className="entries-wrap">
            <span className="select-title">إختار الموظف</span>
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange('status', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الحالات</Option>
              {allUserStatusArr?.length > 0 &&
                allUserStatusArr.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form> */}
      </div>
    </div>
  );
};
export default TableFilter;
