const lngs = [
  {
    code: 'ar', // iso code
    name: 'العربية',
    country_code: 'eg'
    // country_code: "sa"
    // dir: "rtl"
  }
  // {
  //   code: 'en', // iso code
  //   name: 'English',
  //   country_code: 'us'
  // }
];

export default lngs;
