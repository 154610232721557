import React, { useContext } from 'react';
import { Modal } from 'antd';
import parse from 'html-react-parser';
import './SelectedRowDetailModal.scss';
import ServicesContext from '../../contexts/services-context/ServicesContext';
import CustomImage from '../../common/custom-image/CustomImage';

const SelectedRowDetailsModal = () => {
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedTableRow,
    selectedTableRow
  } = useContext(ServicesContext);

  const renderMainInfo = () => {
    return (
      <div className="modal-main-info">
        {selectedTableRow?.name_ar && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الاسم باللغة العربية</div>
            <div className="info-value">{parse(selectedTableRow.name_ar)}</div>
          </div>
        )}
        {selectedTableRow.name_en && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الاسم باللغة الانجليزية</div>
            <div className="info-value">{parse(selectedTableRow.name_en)}</div>
          </div>
        )}
      </div>
    );
  };
  const renderDescInfo = () => {
    return (
      <div
        className="modal-main-info"
        style={{
          marginTop: 22
        }}
      >
        {selectedTableRow?.desc_ar && (
          <div className="custom-render-info-wrap">
            <div className="info-label">وصف الخدمة باللغة العربية</div>
            <div className="info-value">{parse(selectedTableRow.desc_ar)}</div>
          </div>
        )}
        {selectedTableRow.desc_en && (
          <div className="custom-render-info-wrap">
            <div className="info-label">وصف الخدمة باللغة الانجليزية</div>
            <div className="info-value">{parse(selectedTableRow.desc_en)}</div>
          </div>
        )}
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="slide-img-wrap">
        <img src={selectedTableRow.image} alt="" />
      </div>
    );
  };

  const renderImages = () => {
    if (selectedTableRow?.images?.length > 0) {
      return (
        <div className="service-images-ul-warp">
          {selectedTableRow.images.map((img) => (
            <div className="img-wrap">
              <CustomImage src={img?.image} />
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      transitionName=""
      className="slides-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">التفاصيل</div>}
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedTableRow(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedTableRow(null);
      }}
      footer={false}
    >
      {renderImage()}
      {renderMainInfo()}
      {renderDescInfo()}
      {renderImages()}
    </Modal>
  );
};

export default SelectedRowDetailsModal;
