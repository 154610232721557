import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import lngs from '../../languages';
import sleep from '../../utils/sleep';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import mainAppBarLinks from './mainAppBarLinks';
import UserContext from '../../contexts/user-context/UserProvider';
import GlobeIcon from '../../common/icons/GlobeIcon';
import Logo from '../../common/logo/Logo';
import './MainAppBar.scss';
import SelectedPageContext from '../../contexts/selected-page-context/SelectedPageContext';

const { SubMenu } = Menu;

const MainAppBar = () => {
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const { selectedPage } = useContext(SelectedPageContext);
  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };
  return (
    <>
      <div
        className="main-appbar-full-warpper"
        style={{
          width: collapsed ? 80 : 256,
          minHeight: '100vh',
          transition: 'all .3s linear'
        }}
      >
        <Menu
          style={{
            minHeight: '100vh'
          }}
          // defaultSelectedKeys={[`language_key_${i18n.language}`]}
          selectedKeys={[
            `language_key_${i18n.language}`,
            `${selectedPage?.id}`
          ]}
          defaultSelectedKeys={[
            `language_key_${i18n.language}`,
            `${selectedPage?.id}`
          ]}
          defaultOpenKeys={['sub1', 'lang_menu']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
        >
          <Menu.Item
            className="main-appbar-logo-menu-item"
            key="9999"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                  onClick={toggleCollapsed}
                  style={{
                    fontSize: 24
                  }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={toggleCollapsed}
                  style={{
                    fontSize: 24
                  }}
                />
              )
            }
          >
            {!collapsed && (
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  margin: '0 12px',
                  display: 'block'
                }}
              >
                <Logo className="main-appbar-logo" />
              </div>
            )}
          </Menu.Item>
          {mainAppBarLinks(i18n, t, user)?.length > 0 &&
            mainAppBarLinks(i18n, t, user).map((item) => (
              <Menu.Item
                style={{
                  fontSize: 16
                }}
                key={item.id}
                icon={item?.icon}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <RouterLink to={item.link}>{item.name}</RouterLink>
                </div>
              </Menu.Item>
            ))}
          {/* <SubMenu key="sub1" icon={<MailOutlined />} title="Navigation One">
            <Menu.Item key="5">Option 5</Menu.Item>
            <Menu.Item key="6">Option 6</Menu.Item>
            <Menu.Item key="7">Option 7</Menu.Item>
            <Menu.Item key="8">Option 8</Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub2"
            icon={<AppstoreOutlined />}
            title="Navigation Two"
          >
            <Menu.Item key="9">Option 9</Menu.Item>
            <Menu.Item key="10">Option 10</Menu.Item>
            <SubMenu key="sub3" title="Submenu">
              <Menu.Item key="11">Option 11</Menu.Item>
              <Menu.Item key="12">Option 12</Menu.Item>
            </SubMenu>
          </SubMenu> */}
          {i18n.languages?.length > 0 && (
            <SubMenu
              key="lang_menu"
              style={{
                fontSize: 16
              }}
              icon={
                <GlobeIcon
                  width={20}
                  height={20}
                  style={{
                    fontSize: '20px',
                    verticalAlign: 'middle'
                  }}
                />
              }
              title={i18n.language === 'ar' ? 'اللغــة' : 'Language'}
            >
              {lngs?.length > 0 &&
                lngs.map((lng) => (
                  <Menu.Item
                    style={{
                      fontSize: 16
                    }}
                    onClick={async () => {
                      setLoadingModalVisible(true);
                      i18n.changeLanguage(lng.code);
                      await sleep(600);
                      setLoadingModalVisible(false);
                    }}
                    key={'language_key_' + lng.code}
                  >
                    {lng.name}
                  </Menu.Item>
                ))}
            </SubMenu>
          )}
        </Menu>
      </div>
      {loadingModalVisible && <LoadingModal />}
    </>
  );
};

export default MainAppBar;
