/* eslint-disable eqeqeq */
import * as Yup from 'yup';

const createNewFormSchema = Yup.object().shape({
  name_ar: Yup.string().required('أدخل الاسم باللغة العربية'),
  name_en: Yup.string().required('أدخل الاسم باللغة الانجليزية'),
  price: Yup.string()
    .required('أدخل سعر الخدمة')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  image: Yup.mixed().required('أدخل الصورة'),
  hasOffer: Yup.string().nullable().required('إختار نوع الخصم'),
  offerValue: Yup.string().test(
    'offerValue',
    'أدخل نسبة الخصم',
    (v, context) => {
      let result = true;
      if (!v && context?.parent?.hasOffer == '1') {
        result = false;
      }
      return result;
    }
  ),
  maxNumWorker: Yup.string()
    .required('أدخل أقصى عدد من العمال')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
});
const editFormSchema = Yup.object().shape({
  name_ar: Yup.string().required('أدخل الاسم باللغة العربية'),
  name_en: Yup.string().required('أدخل الاسم باللغة الانجليزية'),
  price: Yup.string()
    .required('أدخل سعر الخدمة')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  hasOffer: Yup.string().nullable().required('إختار نوع الخصم'),
  offerValue: Yup.string().test(
    'offerValue',
    'أدخل نسبة الخصم',
    (v, context) => {
      let result = true;
      if (!v && context?.parent?.hasOffer == '1') {
        result = false;
      }
      return result;
    }
  ),
  maxNumWorker: Yup.string()
    .required('أدخل أقصى عدد من العمال')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
});
const getTableFormSchema = (selectedTableRow) => {
  if (!selectedTableRow) {
    return createNewFormSchema;
  }
  return editFormSchema;
};

export default getTableFormSchema;
