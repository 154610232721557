/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import routerLinks from '../../components/app/routerLinks';
import SharedAntdTable from '../../common/shared-antd-table/SharedAntdTable';
import tableColumns from './tableColumns';
import OrderDetailsContext from '../../contexts/order-details-context/OrderDetailsContext';

const PageTable = ({ tableDataSource }) => {
  const { user } = useContext(UserContext);

  const {
    isLoadingTableData,
    setIsLoadingTableData,
    setFormModalOpened,
    setDetailsModalOpened,
    fetchedOrder,
    setFetchTableDataCount,
    setSelectedTableRow,
    tablePagination
  } = useContext(OrderDetailsContext);

  return (
    <SharedAntdTable
      hasPagination={true}
      tableDataSource={tableDataSource}
      tableColumns={tableColumns}
      allFetchedTableData={fetchedOrder?.orders}
      setFormModalOpened={setFormModalOpened}
      setDetailsModalOpened={setDetailsModalOpened}
      setFetchTableDataCount={setFetchTableDataCount}
      isLoadingTableData={isLoadingTableData}
      setIsLoadingTableData={setIsLoadingTableData}
      setSelectedTableRow={setSelectedTableRow}
      tablePaination={tablePagination}
      user={user}
      baseTablePageUrl={routerLinks?.orderDetailsRoute}
    />
  );
};

export default PageTable;
