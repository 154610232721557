import axios from 'axios';

const getAllOrdersApi = async (paramsFilter, token, langIsoCode) => {
  try {
    const url = `/Orders/all`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllOrdersApi;
