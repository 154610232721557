/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Tabs } from 'antd';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import i18n from '../../i18n';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import CustomEditor from '../../common/custom-editor/CustomEditor';
import createNewFaqApi from '../../apis/faq-apis/createNewFaqApi';
import './TableForm.scss';
import FaqContext from '../../contexts/faq-context/FaqContext';

const TableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);

  const {
    setFormModalOpened,
    isLoadingTableData,
    setIsLoadingTableData,
    setFetchTableDataCount,
    selectedTableRow,
    questionEditorStateAr,
    setQuestionEditorStateAr,
    questionEditorStateEn,
    setQuestionEditorStateEn,
    answerEditorStateAr,
    setAnswerEditorStateAr,
    answerEditorStateEn,
    setAnswerEditorStateEn
  } = useContext(FaqContext);
  const {
    setValue,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    // resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      question_ar: '',
      question_en: '',
      answer_ar: '',
      answer_en: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      selectedTableRow?.question_ar &&
        setValue('question_ar', selectedTableRow.question_ar);
      selectedTableRow?.question_en &&
        setValue('question_en', selectedTableRow.question_en);
      selectedTableRow?.answer_ar &&
        setValue('answer_ar', selectedTableRow.answer_ar);
      selectedTableRow?.answer_en &&
        setValue('answer_en', selectedTableRow.answer_en);
    }
  }, [selectedTableRow]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.question_ar) formData.append('question_ar', data.question_ar);
    if (data.question_en) formData.append('question_en', data.question_en);
    if (data.answer_ar) formData.append('answer_ar', data.answer_ar);
    if (data.answer_en) formData.append('answer_en', data.answer_en);

    setIsLoadingTableData(true);

    if (!selectedTableRow) {
      customApiRequest(
        createNewFaqApi(user?.token, formData, i18n.language),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تمت الإضافة بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('FAQ_id', selectedTableRow.id);

      customApiRequest(
        createNewFaqApi(user?.token, formData, i18n.language, true),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="create-delegate-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane forceRender={true} tab="السؤال باللغة العربية" key="1">
            <CustomEditor
              fieldName="question_ar"
              // editorTitle="About tournament ( English )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="السؤال باللغة العربية"
              editorFieldState={questionEditorStateAr}
              setEditorFieldState={setQuestionEditorStateAr}
              fetchedData={selectedTableRow?.question_ar}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            forceRender={true}
            tab="السؤال باللغة الإنجليزية"
            key="2"
          >
            <CustomEditor
              fieldName="question_en"
              // editorTitle="About tournament ( arabic )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="السؤال باللغة الإنجليزية"
              editorFieldState={questionEditorStateEn}
              setEditorFieldState={setQuestionEditorStateEn}
              fetchedData={selectedTableRow?.question_en}
            />
          </Tabs.TabPane>
        </Tabs>
        {/*  */}
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane forceRender={true} tab="الإجابة باللغة العربية" key="1">
            <CustomEditor
              fieldName="answer_ar"
              // editorTitle="About tournament ( English )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="الإجابة باللغة العربية"
              editorFieldState={answerEditorStateAr}
              setEditorFieldState={setAnswerEditorStateAr}
              fetchedData={selectedTableRow?.answer_ar}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            forceRender={true}
            tab="الإجابة باللغة الإنجليزية"
            key="2"
          >
            <CustomEditor
              fieldName="answer_en"
              // editorTitle="About tournament ( arabic )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="الإجابة باللغة الإنجليزية"
              editorFieldState={answerEditorStateEn}
              setEditorFieldState={setAnswerEditorStateEn}
              fetchedData={selectedTableRow?.answer_en}
            />
          </Tabs.TabPane>
        </Tabs>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoadingTableData}
        >
          {selectedTableRow ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
