/* eslint-disable eqeqeq */
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import checkRes from '../../utils/checkRes';
import deleteSingleContactMessageApi from '../../apis/contact-messages-apis/deleteSingleContactMessageApi';
import { ReadOutlined } from '@ant-design/icons';
import countWords from '../../utils/countWords';
import parse from 'html-react-parser';
import trimWords from '../../utils/trimWords';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n
) => {
  const handleDeleteRequest = (id) => {
    setIsLoadingTableData(true);
    customApiRequest(
      deleteSingleContactMessageApi(
        token,
        {
          id: id
        },
        i18n.language
      ),
      (res) => {
        setIsLoadingTableData(false);
        if (checkRes(res)) {
          setFetchTableDataCount((prev) => prev + 1);
          successNotification({
            title: 'العملية تمت بنجاح',
            message: 'تم الحذف بنجاح'
          });
        } else {
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsLoadingTableData(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <Popconfirm
          title="هل أنت متأكد من الحذف"
          onConfirm={() => handleDeleteRequest(record.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف">
            <Button
              size="large"
              type="dashed"
              shape="round"
              danger
              icon={<TrashIcon width={18} height={18} />}
              style={{
                backgroundColor: '#fbebeb',
                padding: 0,
                width: 38,
                height: 38,
                lineHeight: '42px'
              }}
            />
            {/* <div className="delete-btn">
              <TrashIcon />
            </div> */}
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  const handleEditClick = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  return [
    {
      title: 'الإســم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name ? record.name : '---';
      }
    },
    {
      title: 'البريد الاكترونى',
      dataIndex: 'email',
      render: (_, record) => {
        return record?.email ? record.email : '---';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone ? record.phone : '---';
      }
    },
    {
      title: 'الخدمة',
      dataIndex: 'service',
      render: (_, record) => {
        return record?.service ? record.service : '---';
      }
    },
    {
      title: 'موضوع الرسالة',
      dataIndex: 'subject',
      render: (_, record) => {
        return record?.subject ? record.subject : '---';
      }
    },
    {
      title: 'نص الرسالة',
      dataIndex: 'message',
      render: (_, record) => {
        const recordText = String(record.message);
        const wordsCount = countWords(String(record.message));
        if (record.message && wordsCount > 0) {
          if (wordsCount > 3) {
            return (
              <Popover title="اقرأ المزيد" content={parse(record.message)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{parse(trimWords(recordText, 4))}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return <div className="row-cell row-text">{parse(recordText)}</div>;
        } else {
          return '----';
        }
      }
    },

    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
