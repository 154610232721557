import React from 'react';
import ProfilePageForm from './ProfilePageForm';
import './ProfilePage.scss';

const ProfilePage = () => {
  return (
    <div className="shared-custom-page profile-page">
      <div className="custom-container">
        <div className="page-head-wrap">
          <div className="page-title">
            <h2 className="bold-font">بروفايل المستخدم</h2>
          </div>
        </div>

        <div className="img-form-wrap">
          <ProfilePageForm />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
