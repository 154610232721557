/* eslint-disable eqeqeq */
import * as Yup from 'yup';

const createNewFormSchema = Yup.object().shape({
  name_ar: Yup.string().required('أدخل الاسم باللغة العربية'),
  name_en: Yup.string().required('أدخل الاسم باللغة الانجليزية'),
  code: Yup.string()
    .required('أدخل كود الخصم')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
  amount_type: Yup.string().nullable().required('إختار نوع كود الخصم'),
  amount: Yup.string()
    .test('amount', 'أدخل الخصم', (v, context) => {
      let result = true;
      if (
        !v &&
        (context?.parent?.amount_type == '1' ||
          context?.parent?.amount_type == '2')
      ) {
        result = false;
      }
      return result;
    })
    .test('amount', 'لا يسمح الا بكتابة الارقام', (v, context) => {
      let result = true;
      if (v && !v.match(/^[0-9]+$/)) {
        result = false;
      }
      return result;
    })
});
const editFormSchema = Yup.object().shape({
  name_ar: Yup.string().required('أدخل الاسم باللغة العربية'),
  name_en: Yup.string().required('أدخل الاسم باللغة الانجليزية'),
  code: Yup.string()
    .required('أدخل كود الخصم')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
  amount_type: Yup.string().nullable().required('إختار نوع كود الخصم'),
  amount: Yup.string().test('amount', 'أدخل الخصم', (v, context) => {
    let result = true;
    if (
      !v &&
      (context?.parent?.amount_type == '1' ||
        context?.parent?.amount_type == '2')
    ) {
      result = false;
    }
    return result;
  })
});
const getTableFormSchema = (selectedTableRow) => {
  if (!selectedTableRow) {
    return createNewFormSchema;
  }
  return editFormSchema;
};

export default getTableFormSchema;
