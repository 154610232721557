/* eslint-disable eqeqeq */
import { Button, Popconfirm, Popover, Rate, Tooltip } from 'antd';
import { EyeOutlined, ReadOutlined } from '@ant-design/icons';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import EditIcon from '../../common/icons/EditIcon';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import parse from 'html-react-parser';
import TrashIcon from '../../common/icons/TrashIcon';
import checkRes from '../../utils/checkRes';
import deleteSingleValueApi from '../../apis/values-apis/deleteSingleValueApi';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n
) => {
  const handleDeleteRequest = (id) => {
    setIsLoadingTableData(true);
    customApiRequest(
      deleteSingleValueApi(
        token,
        {
          OurValue_id: id
        },
        i18n.language
      ),
      (res) => {
        setIsLoadingTableData(false);
        if (checkRes(res)) {
          setFetchTableDataCount((prev) => prev + 1);
          successNotification({
            title: 'العملية تمت بنجاح',
            message: 'تم الحذف بنجاح'
          });
        } else {
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsLoadingTableData(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(record)}>
          <EditIcon />
          تعديل
        </div>
        <Popconfirm
          title="هل أنت متأكد من الحذف"
          onConfirm={() => handleDeleteRequest(record.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف">
            <Button
              size="large"
              type="dashed"
              shape="round"
              danger
              icon={<TrashIcon width={18} height={18} />}
              style={{
                backgroundColor: '#fbebeb',
                padding: 0,
                width: 38,
                height: 38,
                lineHeight: '42px'
              }}
            />
            {/* <div className="delete-btn">
              <TrashIcon />
            </div> */}
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  const handleEditClick = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  const handleViewDelegateDetails = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDetailsModalOpened(true);
    }
  };

  return [
    {
      title: 'الصورة',
      dataIndex: 'icon',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="img-wrap">
              {record?.icon ? (
                <img
                  style={{
                    width: 62,
                    height: 62,
                    borderRadius: 4,
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    border: '1px solid rgba(0, 0, 0, .1)'
                  }}
                  src={record.icon}
                  alt={record.name}
                />
              ) : (
                ''
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الاســم بالعربية',
      dataIndex: 'name_ar',
      render: (_, record) => {
        const recordText = String(record.name_ar);
        const wordsCount = countWords(String(record.name_ar));
        if (record.name_ar && wordsCount > 0) {
          if (wordsCount > 3) {
            return (
              <Popover title="اقرأ المزيد" content={parse(record.name_ar)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{parse(trimWords(recordText, 4))}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return <div className="row-cell row-text">{parse(recordText)}</div>;
        } else {
          return '----';
        }
      }
    },
    {
      title: 'الاســم بالانجليزية',
      dataIndex: 'name_en',
      render: (_, record) => {
        const recordText = String(record.name_en);
        const wordsCount = countWords(String(record.name_en));
        if (record.name_en && wordsCount > 0) {
          if (wordsCount > 3) {
            return (
              <Popover title="اقرأ المزيد" content={parse(record.name_en)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{parse(trimWords(recordText, 4))}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return <div className="row-cell row-text">{parse(recordText)}</div>;
        } else {
          return '----';
        }
      }
    },

    {
      title: 'عرض التفاصيل',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <Button
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => handleViewDelegateDetails(record)}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
