import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import { EditorState } from 'draft-js';
import FaqContext from '../../contexts/faq-context/FaqContext';

const TableFormModal = () => {
  const {
    formModalOpened,
    setFormModalOpened,
    selectedTableRow,
    setSelectedTableRow,
    setQuestionEditorStateAr,
    setQuestionEditorStateEn,
    setAnswerEditorStateAr,
    setAnswerEditorStateEn
  } = useContext(FaqContext);

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedTableRow ? 'تعديل' : 'إضافة جديد'}
        </div>
      }
      visible={formModalOpened}
      onOk={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
        setQuestionEditorStateAr(EditorState.createEmpty());
        setQuestionEditorStateEn(EditorState.createEmpty());
        setAnswerEditorStateAr(EditorState.createEmpty());
        setAnswerEditorStateEn(EditorState.createEmpty());
      }}
      onCancel={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
        setQuestionEditorStateAr(EditorState.createEmpty());
        setQuestionEditorStateEn(EditorState.createEmpty());
        setAnswerEditorStateAr(EditorState.createEmpty());
        setAnswerEditorStateEn(EditorState.createEmpty());
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default TableFormModal;
