import axios from 'axios';

const createNewSlideApi = async (token, values, langIsoCode, edit = false) => {
  const url = `/Slider/${edit ? 'edit' : 'create'}`;
  try {
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createNewSlideApi;
