/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import PageTable from './PageTable';
import SelectedRowDetailsModal from './SelectedRowDetailsModal';
import TableFormModal from './TableFormModal';
import useSelectedPage from '../../custom-hooks/useSelectedPage';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import appPages from '../../appPages';
import { useParams } from 'react-router-dom';
import './OrderDetailsPage.scss';
import { Descriptions } from 'antd';
import OrderDetailsContext from '../../contexts/order-details-context/OrderDetailsContext';
import queryString from 'query-string';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getSingleOrderApi from '../../apis/orders-apis/getSingleOrderApi';
import checkRes from '../../utils/checkRes';
import { useLocation } from 'react-router-dom';

const OrderDetailsPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    formModalOpened,
    detailsModalOpened,
    setIsLoadingTableData,
    setFetchedOrder,
    fetchedOrder,
    tableFilter,
    fetchTableDataCount,
    setTablePagination
  } = useContext(OrderDetailsContext);
  const { orderDetailsPage } = appPages(i18n, t, user);
  const params = useParams();
  useSelectedPage({
    altName: (
      <div className="title-icon-text-wrap">
        <span className="title-span">
          {orderDetailsPage(params?.orderId)?.name}
        </span>
      </div>
    ),
    currentPage: orderDetailsPage(params?.orderId)
  });

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getSingleOrderApi(
        {
          ...tableFilter,
          ...values,
          orderId: params?.orderId
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedOrder({ ...data });
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (fetchedOrder && fetchedOrder?.orders?.length >= 0) {
      const mappedData = mapDataSource(fetchedOrder.orders);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, fetchedOrder]);

  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        {fetchedOrder && (
          <Descriptions
            bordered
            column={1}
            style={{
              backgroundColor: '#fff',
              maxWidth: '600px'
            }}
          >
            {fetchedOrder?.servicePrice && (
              <Descriptions.Item label="سعر الخدمة">
                {fetchedOrder.servicePrice}
              </Descriptions.Item>
            )}
            {fetchedOrder?.discountPrice && (
              <Descriptions.Item label="سعر الخصم">
                {fetchedOrder.discountPrice}
              </Descriptions.Item>
            )}
            {fetchedOrder?.note && (
              <Descriptions.Item label="ملاحظات : ">
                {fetchedOrder.note}
              </Descriptions.Item>
            )}
            {fetchedOrder?.totalPrice && (
              <Descriptions.Item label="السعر الإجمالى : ">
                {fetchedOrder.totalPrice}
              </Descriptions.Item>
            )}
            {fetchedOrder?.created_at && (
              <Descriptions.Item label="تاريخ الطلب : ">
                {fetchedOrder.created_at}
              </Descriptions.Item>
            )}
          </Descriptions>
        )}

        <PageTable tableDataSource={tableDataSource} />

        {/* {formModalOpened && <TableFormModal />}
        {detailsModalOpened && <SelectedRowDetailsModal />} */}
      </div>
    </div>
  );
};

export default OrderDetailsPage;
