/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import checkRes from '../../utils/checkRes';
import UserContext from '../../contexts/user-context/UserProvider';
import routerLinks from '../../components/app/routerLinks';
import SharedAntdTable from '../../common/shared-antd-table/SharedAntdTable';
import tableColumns from './tableColumns';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import i18n from '../../i18n';
import getAllFaqApi from '../../apis/faq-apis/getAllFaqsApi';
import FaqContext from '../../contexts/faq-context/FaqContext';

const PageTable = () => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingTableData,
    setIsLoadingTableData,
    setFormModalOpened,
    setDetailsModalOpened,
    allFetchedTableData,
    setAllFetchedTableData,
    fetchTableDataCount,
    setFetchTableDataCount,
    setSelectedTableRow,
    tablePagination,
    setTablePagination,
    tableFilter
  } = useContext(FaqContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getAllFaqApi(
        {
          ...tableFilter,
          ...values
          // seller_id: user?.id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, i18n.language]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(allFetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, allFetchedTableData, allFetchedTableData?.length]);

  return (
    <SharedAntdTable
      hasPagination={true}
      tableDataSource={tableDataSource}
      tableColumns={tableColumns}
      allFetchedTableData={allFetchedTableData}
      setFormModalOpened={setFormModalOpened}
      setDetailsModalOpened={setDetailsModalOpened}
      setFetchTableDataCount={setFetchTableDataCount}
      isLoadingTableData={isLoadingTableData}
      setIsLoadingTableData={setIsLoadingTableData}
      setSelectedTableRow={setSelectedTableRow}
      tablePaination={tablePagination}
      user={user}
      baseTablePageUrl={routerLinks?.ourValuesRoute}
    />
  );
};

export default PageTable;
