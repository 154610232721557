/* eslint-disable eqeqeq */
import { Button, Popover, Tooltip } from 'antd';
import { EyeOutlined, ReadOutlined } from '@ant-design/icons';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import parse from 'html-react-parser';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n
) => {
  const handleViewDelegateDetails = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDetailsModalOpened(true);
    }
  };

  return [
    {
      title: 'الخدمة',
      dataIndex: 'service',
      render: (_, record) => {
        return record?.service?.name ? record.service.name : '---';
      }
    },
    {
      title: 'سعر الطلب',
      dataIndex: 'price',
      render: (_, record) => {
        return record?.price;
      }
    },
    {
      title: 'الباقة',
      dataIndex: 'package',
      render: (_, record) => {
        return record?.package?.name ? record.package.name : '---';
      }
    },
    {
      title: 'صاحب الطلب',
      dataIndex: 'user',
      render: (_, record) => {
        return record?.user?.name ? record.user.name : '---';
      }
    },
    {
      title: 'العامل',
      dataIndex: 'worker',
      render: (_, record) => {
        return record?.worker?.name ? record.worker.name : '---';
      }
    },
    {
      title: 'ملاحظات',
      dataIndex: 'note',
      render: (_, record) => {
        const recordText = String(record.note);
        const wordsCount = countWords(String(record.note));
        if (record.note && wordsCount > 0) {
          if (wordsCount > 3) {
            return (
              <Popover title="اقرأ المزيد" content={parse(record.note)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{parse(trimWords(recordText, 4))}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return <div className="row-cell row-text">{parse(recordText)}</div>;
        } else {
          return '----';
        }
      }
    },

    {
      title: 'عرض التفاصيل',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <Button
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => handleViewDelegateDetails(record)}
            />
          </Tooltip>
        );
      }
    }
  ];
};

export default tableColumns;
