import axios from 'axios';

const createNewCategoryApi = async (
  token,
  values,
  langIsoCode,
  edit = false
) => {
  const url = `/Category/${edit ? 'edit' : 'create'}`;
  try {
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createNewCategoryApi;
