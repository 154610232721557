import * as Yup from 'yup';

const createNewFormSchema = Yup.object().shape({
  text_ar: Yup.string().required('أدخل النص باللغة العربية'),
  text_en: Yup.string().required('أدخل النص باللغة الانجليزية'),
  image: Yup.mixed().required('أدخل الصورة')
});
const editFormSchema = Yup.object().shape({
  text_ar: Yup.string().required('أدخل النص باللغة العربية'),
  text_en: Yup.string().required('أدخل النص باللغة الانجليزية')
});
const getTableFormSchema = (selectedTableRow) => {
  if (!selectedTableRow) {
    return createNewFormSchema;
  }
  return editFormSchema;
};

export default getTableFormSchema;
