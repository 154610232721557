/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Tabs } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import FileInput from '../../common/file-input/FileInput';
import getTableFormSchema from './tableFormSchema';
import createNewSlideApi from '../../apis/slides-apis/createNewSlideApi';
import i18n from '../../i18n';
import SlidesContext from '../../contexts/slides-context/SlidesContext';
import CustomEditor from '../../common/custom-editor/CustomEditor';
import './TableForm.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';

const TableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);

  const {
    setFormModalOpened,
    isLoadingTableData,
    setIsLoadingTableData,
    setFetchTableDataCount,
    selectedTableRow,
    sliderEditorStateAr,
    setSliderEditorStateAr,
    sliderEditorStateEn,
    setSliderEditorStateEn
  } = useContext(SlidesContext);
  const schema = getTableFormSchema(selectedTableRow);
  const {
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      text_ar: '',
      text_en: '',
      image: null
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.text_ar) formData.append('text_ar', data.text_ar);
    if (data.text_en) formData.append('text_en', data.text_en);
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }

    setIsLoadingTableData(true);

    if (!selectedTableRow) {
      customApiRequest(
        createNewSlideApi(user?.token, formData, i18n.language),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تمت الإضافة بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('Slider_id', selectedTableRow.id);

      customApiRequest(
        createNewSlideApi(user?.token, formData, i18n.language, true),
        (res) => {
          setIsLoadingTableData(false);
          if (checkRes(res)) {
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoadingTableData(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="create-delegate-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane forceRender={true} tab="النص باللغة العربية" key="1">
            <CustomEditor
              fieldName="text_ar"
              // editorTitle="About tournament ( English )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="أدخل النص باللغة العربية"
              editorFieldState={sliderEditorStateAr}
              setEditorFieldState={setSliderEditorStateAr}
              fetchedData={selectedTableRow?.text_ar}
            />
          </Tabs.TabPane>
          <Tabs.TabPane forceRender={true} tab="النص باللغة الإنجليزية" key="2">
            <CustomEditor
              fieldName="text_en"
              // editorTitle="About tournament ( arabic )"
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              required={false}
              errorMsg="أدخل النص باللغة الإنجليزية"
              editorFieldState={sliderEditorStateEn}
              setEditorFieldState={setSliderEditorStateEn}
              fetchedData={selectedTableRow?.text_en}
            />
          </Tabs.TabPane>
        </Tabs>

        <div className="file-inputs-wrap">
          <FileInput
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="الصورة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoadingTableData}
        >
          {selectedTableRow ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
