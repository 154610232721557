import React from 'react';

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.907"
      height="12.607"
      viewBox="0 0 9.907 12.607"
    >
      <g id="delete_6_" data-name="delete (6)" transform="translate(-63.818)">
        <path
          id="Path_70"
          data-name="Path 70"
          d="M72.873,2.132l-2.42.662-.008,0a.619.619,0,0,0-.433.581v.67H67.621l2.733-2.611a.358.358,0,0,0,0-.522L69.662.252a.933.933,0,0,0-1.276,0l-.712.68L67.309.584a1.067,1.067,0,0,0-1.458,0L64.429,1.942a.955.955,0,0,0,0,1.393l.365.348-.712.68a.836.836,0,0,0,0,1.219l.693.662a.4.4,0,0,0,.547,0l.98-.936v5.7a1.641,1.641,0,0,0,1.675,1.6h4.073a1.641,1.641,0,0,0,1.675-1.6V2.715A.641.641,0,0,0,72.873,2.132Zm-7.9.333L66.4,1.107a.267.267,0,0,1,.365,0l.365.348L65.341,3.161l-.365-.348A.239.239,0,0,1,64.976,2.465Zm.072,3-.419-.4a.119.119,0,0,1,0-.174l4.3-4.112a.133.133,0,0,1,.182,0l.419.4Zm7.9,5.546a.884.884,0,0,1-.9.862H67.976a.884.884,0,0,1-.9-.862V4.786h5.877Zm0-6.939-2.166-.02V3.467l2.166-.583Z"
          transform="translate(0)"
          fill="#fff"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M222.791,238.409a.36.36,0,0,0,.36-.36v-4.311a.36.36,0,0,0-.72,0v4.311A.36.36,0,0,0,222.791,238.409Z"
          transform="translate(-154.501 -227.458)"
          fill="#fff"
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M289.532,238.409a.36.36,0,0,0,.36-.36v-4.311a.36.36,0,0,0-.72,0v4.311A.36.36,0,0,0,289.532,238.409Z"
          transform="translate(-219.512 -227.458)"
          fill="#fff"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M356.272,238.409a.36.36,0,0,0,.36-.36v-4.311a.36.36,0,0,0-.72,0v4.311A.36.36,0,0,0,356.272,238.409Z"
          transform="translate(-284.522 -227.458)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
