import axios from 'axios';

const deleteSingleSlideApi = async (token, values, langIsoCode) => {
  const url = `/Slider/delete`;
  try {
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteSingleSlideApi;
